import React, { useState, useEffect } from "react";
import axios from "axios";

export const Accountsettings = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;
  const [accountSettings, setAccountSettings] = useState([]);


    const fetchAccountSettings = async () => {
      try {
        const response = await axios.get(`https://kycztm.dreamadda.in/User/accountSettings`);
        if (response.data.status === 'success') {
          setAccountSettings(response.data.accountSettings.map((setting, index) => ({
            ...setting,
            id: index
          })));
        } else {
          console.error("Failed to fetch account settings");
        }
      } catch (error) {
        console.error("Error fetching account settings:", error);
      }
    };

  useEffect(() => {
    fetchAccountSettings();
  }, [baseUrl]);

  const handleAddAccount = () => {
    setAccountSettings([...accountSettings, { id: Date.now(), accountNumber: "", beneficiaryName: "", ifscCode: "", isEnabled: false }]);
  };

  // const handleRemoveAccount = (id) => {
  //   setAccountSettings(accountSettings.filter(setting => setting.id !== id));
  // };


  const handleRemoveAccount = async (id) => {
    try {
      await axios.delete(`https://kycztm.dreamadda.in/User/accountSettings/${id}`);
      setAccountSettings(accountSettings.filter(setting => setting.id !== id));
      alert("Account Delete successfully removed.");
  
      fetchAccountSettings();
    } catch (error) {
      console.error("Error deleting Account setting:", error);
      alert("Failed to remove Account setting.");
    }
  };




  const handleToggleChange = (id) => {
    const updatedSettings = accountSettings.map(setting =>
      setting.id === id
        ? { ...setting, isEnabled: !setting.isEnabled }
        : { ...setting, isEnabled: false }
    );

    const activeSettings = updatedSettings.filter(setting => setting.isEnabled);
    if (activeSettings.length > 1) {
      alert("Only one setting can be active at a time");
      return;
    }

    setAccountSettings(updatedSettings);
  };

  const handleInputChange = (id, field, value) => {
    setAccountSettings(accountSettings.map(setting =>
      setting.id === id ? { ...setting, [field]: value } : setting
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const activeSetting = accountSettings.find(setting => setting.isEnabled);

    if (!activeSetting) {
      alert("Please activate one account setting before submitting.");
      return;
    }

    try {
      const response = await axios.post(`https://kycztm.dreamadda.in/User/accountSettings`, {
        accountSettings
      });
      if (response.data.status === 'success') {
        alert("Settings submitted successfully");
      } else {
        alert("Settings Not Submitted");
      }
    } catch (error) {
      console.error("Error submitting settings:", error);
      alert("Error submitting settings");
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="text-uppercase font-weight-bold my-3">Account Settings</h3>
          <button type="button" className="btn btn-primary" onClick={handleAddAccount}>
            Add Account
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          {accountSettings.map((setting) => (
            <div key={setting.id} className="form-row align-items-center mb-2">
              <div className="form-group col-md-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account Number"
                  value={setting.accountNumber}
                  onChange={(e) => handleInputChange(setting.id, "accountNumber", e.target.value)}
                />
              </div>
              <div className="form-group col-md-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Beneficiary Name"
                  value={setting.beneficiaryName}
                  onChange={(e) => handleInputChange(setting.id, "beneficiaryName", e.target.value)}
                />
              </div>
              <div className="form-group col-md-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="IFSC Code"
                  value={setting.ifscCode}
                  onChange={(e) => handleInputChange(setting.id, "ifscCode", e.target.value)}
                />
              </div>
              <div className="form-group col-md-2">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`customSwitch${setting.id}`}
                    checked={setting.isEnabled}
                    onChange={() => handleToggleChange(setting.id)}
                  />
                  <label className="custom-control-label" htmlFor={`customSwitch${setting.id}`}>
                    {setting.isEnabled ? "On" : "Off"}
                  </label>
                </div>
              </div>
              <div className="form-group col-md-1">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveAccount(setting._id)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <div className="form-row">
            <div className="form-group col-md-12">
              <button type="submit" className="btn btn-dark">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
